"use client"
import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import Link from "next/link";
import Image from "next/image";
import ChocoIcon from "@/../public/images/chocoicon.png"
import CakeIcon from "@/../public/images/hero-side-bg.png"
import BakeryIcon from "@/../public/images/services1.webp"
import DonutIcon from "@/../public/images/services4.webp"

const HeroSection = () => {
  const [imageData, setImageData] = useState<{ url: string; type: string }[] | null>(null);

  // Decorative elements data
  const decorativeImages = [
    { src: ChocoIcon.src, alt: "Choco Icon", className: "absolute top-10 left-10 animate-float" },
    { src: CakeIcon.src, alt: "Cake Icon", className: "absolute bottom-20 right-20 animate-float-delayed" },
    { src: BakeryIcon.src, alt: "Bakery Icon", className: "absolute top-20 right-40 animate-float" },
    { src: DonutIcon.src, alt: "Donut Icon", className: "absolute bottom-10 left-40 animate-float-delayed" },
  ];

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          "https://api.marutibakersmart.com/v1/public/images/shop"
        );
        const result = await response.json();
        if (result.status === "success") {
          setImageData(
            Array.isArray(result.data)
              ? result.data
              : [{ url: result.data.url, type: result.data.type }]
          );
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    const decoratives = document.querySelectorAll(".decorative-image");
    const heading = document.querySelector(".hero-heading");
    const subheading = document.querySelector(".hero-subheading");
    const button = document.querySelector(".hero-button");

    gsap.fromTo(
      mainContent,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );

    gsap.fromTo(
      decoratives,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.8, stagger: 0.2, ease: "back.out" }
    );

    gsap.fromTo(
      [heading, subheading],
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, duration: 1, stagger: 0.3, ease: "power2.out" }
    );

    gsap.fromTo(
      button,
      { scale: 0.8, opacity: 0 },
      { scale: 1, opacity: 1, duration: 1, ease: "elastic.out(1, 0.3)", delay: 1 }
    );
  }, []);

  return (
    <section className="w-full relative overflow-hidden bg-gradient-to-b from-orange-50 to-white min-h-screen">
      {/* Decorative background pattern */}
      <div className="absolute inset-0 bg-[radial-gradient(#FDE2E2_1px,transparent_1px)] [background-size:16px_16px] opacity-50" />
      
      <main className="max-w-[1380px] mx-auto relative">
        <div className="min-h-fit lg:min-h-[80vh] pt-4 lg:pt-10 w-full flex flex-col lg:flex-row px-5 gap-10 lg:gap-20 items-center main-content">
          {/* Left content */}
          <div className="flex-1 w-full flex flex-col gap-8 items-start justify-center pt-20 lg:pt-0 z-10">
            <div className="flex flex-col gap-6">
              <h1 className="hero-heading text-4xl md:text-6xl lg:text-7xl font-HeroNew font-bold leading-tight text-gray-800">
                Where Quality <span className="text-orange-600">Ingredients</span> Rise to Perfection!
              </h1>
              <p className="hero-subheading text-lg md:text-xl text-gray-600 max-w-[600px]">
                Discover our handcrafted cakes and pastries made with love and the finest ingredients.
              </p>
            </div>
            <Link
              href="/shop"
              className="hero-button py-3 px-8 bg-orange-600 text-white hover:bg-orange-700 text-lg font-HeroNew font-bold rounded-full transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
            >
              SHOP NOW
            </Link>
          </div>

          {/* Right content */}
          <div className="flex-1 w-full flex-center relative">
            <div className="relative w-full aspect-square max-w-[600px]">
              {imageData && imageData.map((image, index) => (
                <Image
                  key={image.url}
                  src={image.url}
                  alt={`Featured cake ${index + 1}`}
                  width={600}
                  height={600}
                  className="w-full h-full object-contain transform hover:scale-105 transition-transform duration-300"
                  priority={index === 0}
                />
              ))}
              
              {/* Decorative floating elements */}
              {decorativeImages.map((img, index) => (
                <div
                  key={index}
                  className={`decorative-image ${img.className} w-24 h-24 md:w-32 md:h-32 rounded-full bg-orange-200 opacity-70 flex items-center justify-center`}
                >
                  <Image
                    src={img.src}
                    alt={img.alt}
                    width={80}
                    height={80}
                    className="w-16 h-16 md:w-20 md:h-20 object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      {/* Add global styles */}
      <style jsx global>{`
        @keyframes float {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-10px); }
        }
        @keyframes float-delayed {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-10px); }
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
        .animate-float-delayed {
          animation: float-delayed 3s ease-in-out infinite;
          animation-delay: 1.5s;
        }
      `}</style>
    </section>
  );
};

export default HeroSection;

